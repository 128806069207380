import {
  Box,
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  Backdrop,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { CreateCallCase } from "../services/CustomerService";
import UseTranslation from "../services/UseTranslation";
import Defines from "../config";
import { getfileType } from "../services/Shared";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../types/Master";
import { Dispatch } from "redux";
import { action_types, SET_CALL_SUCCESS } from "../types/ActionTypes";
import { GetConfigInfo } from "../services/CustomerService";
import { config } from "process";
import { ConfigInfo } from "../types/Master";

const translation = UseTranslation();
declare var grecaptcha: any;

const mapStateToProps = (state: ApplicationState) => ({
  callSuccess: state.callSuccess,
});
const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    SetCallSuccess: (val: boolean) =>
      dispatch({ type: "SET_CALL_SUCCESS", payload: val }),
  };
};

const CallForm = (props: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCallformOnline, setIsCallformOnline] = useState<string>("");
  const [Navn, setNavn] = React.useState<string>("");
  const [Email, setEmail] = React.useState<string>("");
  const [Emne, setEmne] = React.useState<string>("");
  const [Telefonnummer, setTelefonnummer] = React.useState<string>("");
  const [Kategori, setKategori] = React.useState<string>("");
  const [Beskrivelse, setBeskrivelse] = React.useState<string>("");
  const [isAcceptReturnPolicy, setIsAcceptReturnPolicy] =
    useState<boolean>(false);
  const [EmailValidationMessage, setEmailValidationMessage] =
    React.useState<string>("");
  const [files, setFiles] = useState<FileList | null>();
  const [isFileSizeExceeded, setFileSizeExceeded] = useState<boolean>(false);
  const [ErrorMessage, setErrorMessage] = React.useState<string>("");
  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    if (files && files.length > 0 && files[0].size > 25 * 1024 * 1024) {
      setFileSizeExceeded(true);
    } else {
      setFiles(event.target.files);
      setFileSizeExceeded(false);
    }
  };
  useEffect(() => {
    GetConfigInfo()
      .then((res) => {
        if (res) {
          if (res != null) {
            setIsCallformOnline(res.EnableCallback);
          }
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, []);
  const history = useHistory();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      const data = new FormData(event.currentTarget);
      setIsLoading(true);
      if (files && files.length > 0) {
        let file = files[0];
        let fileName = file.name;
        let extension = fileName.split(".").pop();
        let fileType = extension
          ? getfileType(extension)
          : "application/octet-stream";
        data.append("fileName", fileName);
        data.append("file", file);
        data.append("contentType", fileType);
      }
      grecaptcha.ready(function () {
        grecaptcha
          .execute(Defines.reCAPTCHA_SITE_KEY, { action: "submit" })
          .then(function (token: any) {
            data.append("token", token);
            CreateCallCase(data)
              .then((res) => {
                setIsLoading(false);
                if (res.status == "Queue_Error") {
                  props.SetCallSuccess(false);
                  setToDefaults();
                  alert(
                    "Submit action Failed. Please refresh page and try again."
                  );
                } else if (res.status == "Recaptcha_Error") {
                  props.SetCallSuccess(false);
                  setErrorMessage(translation.recaptchafailuremsg);
                } else if (res.status != "Error") {
                  props.SetCallSuccess(true);
                  setToDefaults();
                } else {
                  props.SetCallSuccess(false);
                  setErrorMessage(translation.techinicalerrormsg);
                }
              })
              .catch((ex) => {
                setIsLoading(false);
                props.SetCallSuccess(false);
                setErrorMessage(translation.techinicalerrormsg);
                console.log(ex);
              });
          });
      });
    }
  };
  const validate = () => {
    let isValid = true;
    setErrorMessage("");
    setEmailValidationMessage("");
    if (Email != "") {
      const isEmailValid = /@/.test(Email);
      if (!isEmailValid) {
        setEmailValidationMessage(translation.InvalidEmailAddress);
        isValid = false;
      }
    }
    return isValid;
  };
  const setToDefaults = () => {
    setNavn("");
    setEmail("");
    setEmne("");
    setTelefonnummer("");
    setKategori("");
    setBeskrivelse("");
    setFiles(null);
    setFileSizeExceeded(false);
    setIsAcceptReturnPolicy(false);
    setEmailValidationMessage("");
  };
  const onLukClick = () => {
    history.push("/");
  };

  return (
    <React.Fragment>
      <Box>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <Box className="loading-icon" />
        </Backdrop>
        {isCallformOnline == "false" && (
          <Box className="success-wrapper mt-8">
            <Typography style={{ fontSize: "20px" }}>
              <strong>{translation.OfflineASAP}</strong>
            </Typography>
            <Button value="Luk" className="btn-success" onClick={onLukClick}>
              {translation.close}
            </Button>
          </Box>
        )}
        {isCallformOnline == "true" && !props.callSuccess && (
          <Box
            component="form"
            className="form-section"
            onSubmit={handleSubmit}
          >
            <React.Fragment>
              <Typography>
                <h3 className="light-grey-color-02">
                  {translation.callFormHeading}
                </h3>
              </Typography>
              <Typography>
                <h6 className="mb-3 light-grey-color-02">
                  {translation.callFormSubHeading}
                </h6>
              </Typography>
            </React.Fragment>

            <Card className="">
              <TextField
                required
                id="Navn-textbox"
                label="Navn"
                name="Navn"
                variant="outlined"
                fullWidth
                value={Navn}
                onChange={(e) => setNavn(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
              <div className="bi-errorMessageBlock">
                <TextField
                  required
                  id="Email-textbox"
                  label="Email"
                  name="Email"
                  variant="outlined"
                  fullWidth
                  value={Email}
                  onChange={(e) => setEmail(e.target.value as string)}
                  onInvalid={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity(
                      "Udfyld venligst dette felt"
                    )
                  }
                  onInput={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity("")
                  }
                />{" "}
                <span className="text-danger">{EmailValidationMessage} </span>
              </div>
              <TextField
                required
                id="Telefonnummer-textbox"
                label="Telefonnummer"
                name="Telefonnummer"
                variant="outlined"
                fullWidth
                value={Telefonnummer}
                onKeyPress={(e) =>
                  !/^[ +()0-9]+$/.test(e.key) && e.preventDefault()
                }
                onChange={(e) => setTelefonnummer(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
              <FormControl fullWidth className="contact-select">
                <InputLabel htmlFor="select-case-type">Kategori *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="select-case-type"
                  name="Kategori"
                  label={"Kategori *"}
                  fullWidth
                  className="dropdown"
                  style={{ minWidth: 120 }}
                  required
                  value={Kategori}
                  onChange={(e) => {
                    e.preventDefault();
                    setKategori(e.target.value as string);
                  }}
                  onInvalid={(e) => {
                    if (Kategori !== "") {
                      (e.target as HTMLInputElement).setCustomValidity("");
                      e.preventDefault();
                    } else {
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Udfyld venligst dette felt"
                      );
                    }
                  }}
                >
                  <MenuItem value={""}>- Ingen -</MenuItem>
                  <MenuItem value={"Andet"}>Andet</MenuItem>
                  <MenuItem value={"Administration"}>Administration</MenuItem>
                  <MenuItem value={"Annoncering"}>Annoncering</MenuItem>
                  <MenuItem value={"Finansiering"}>Finansiering</MenuItem>
                  <MenuItem value={"Hjemmesider"}>Hjemmesider</MenuItem>
                  <MenuItem value={"Kundesager"}>Kundesager</MenuItem>
                  <MenuItem value={"Priser og abonnementer"}>
                    Priser og abonnementer
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="Emne-textbox"
                label={translation.category}
                name="Emne"
                variant="outlined"
                fullWidth
                value={Emne}
                onChange={(e) => setEmne(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
              <TextField
                required
                id="Beskrivelse-textbox"
                label="Beskrivelse"
                name="Beskrivelse"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                maxRows={4}
                value={Beskrivelse}
                onChange={(e) => setBeskrivelse(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
            </Card>
            <Box className="d-flex mb-3">
              <Button
                variant="contained"
                component="label"
                className="upload-file"
              >
                {"Attachments"}
                <input
                  type="file"
                  accept="image/*,.csv,.pdf,.txt,.doc,.docx,.xml"
                  onChange={(e) => handleFilesAdded(e)}
                  hidden
                />
              </Button>
              {files && (
                <Typography className="font-16-bold line-height-50">
                  {files[0].name}
                </Typography>
              )}
            </Box>
            {isFileSizeExceeded && (
              <Box className="d-flex mb-3">
                <Typography className="font-16-bold line-height-50">
                  {translation.FileSizeExceed}{" "}
                </Typography>
              </Box>
            )}
            <Box className="form-checkbox">
              <Checkbox
                name="AcceptReturnPolicy"
                value={isAcceptReturnPolicy}
                onClick={() => setIsAcceptReturnPolicy(!isAcceptReturnPolicy)}
                color="primary"
                checked={isAcceptReturnPolicy}
                className="email-checkbox"
              />
              {translation.emailFormReturnTerms}
              <a href={Defines.POLICY_URL} className="ml-1" target="_blank">
                her
              </a>
            </Box>
            {ErrorMessage != "" && (
              <div className="captcha-error mb-2">
                <span>{ErrorMessage}</span>
              </div>
            )}
            <Box className="justify-content-end d-flex">
              <Button
                type="submit"
                variant="contained"
                className="ms-auto btn-button btn-blue"
                disabled={!isAcceptReturnPolicy}
              >
                {"Send"}
              </Button>
            </Box>
          </Box>
        )}
        {props.callSuccess && isCallformOnline == "true" && (
          <Box className="success-wrapper mt-4">
            <h4>
              <span className="checkSuccess"></span>
              {translation.Thankyou}
            </h4>
            <Typography>{translation.ReturnASAP}</Typography>
            <Button value="Luk" className="btn-success" onClick={onLukClick}>
              {translation.close}
            </Button>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CallForm);
