import { article, AlertMessage, ConfigInfo } from "../types/Master";

const defaultGetOptions = {
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  }),
};

const defaultPostOptions = {
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  }),
};

export const CreateEmailCase = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Support/CreateEmailCase", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreateCallCase = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Support/CreateCallCase", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetArticles = (): Promise<article[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Articles/GetArticles", {
      method: "GET",
      ...defaultGetOptions,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null) {
          resolve(JSON.parse(response.data));
        }
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetConfigInfo = (): Promise<ConfigInfo | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Config/GetConfigInfo", {
      method: "GET",
      ...defaultGetOptions,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetArticleDetailContent = (article: article): Promise<article> => {
  return new Promise((resolve, reject) => {
    fetch("api/Articles/GetArticleDetailContent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(article),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetAlertMessage = (): Promise<AlertMessage[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/AlertMessage/GetMessage", {
      method: "GET",
      ...defaultGetOptions,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null) {
          resolve(JSON.parse(response.data));
        }
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const VerifyRecaptchaToken = (
  url: string
): Promise<{ status: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Support/VerifyRecaptchaToken", {
      method: "POST",
      body: url,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status });
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};
