import * as React from "react";
import { Container } from "reactstrap";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../types/Master";
import { action_types } from "../types/ActionTypes";
import { Dispatch } from "redux";
import UseTranslation from "../services/UseTranslation";
import "./index.css";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  formSuccess: state.formSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    SetSuccess: (val: boolean) =>
      dispatch({ type: "SET_SUCCESS", payload: val }),
    SetCallSuccess: (val: boolean) =>
      dispatch({ type: "SET_CALL_SUCCESS", payload: val }),
  };
};

const Footer = (props: any) => {
  const onemailsubmit = () => {
    props.SetSuccess(false);
  };

  return (
    <Box className="footer-section py-3">
      <Container>
              <footer className="row justify-content-end">         
          <Box className="col-xs-12 my-3 bi-footer-alignRight">
            <Link
              to="/contactsupport"
              onClick={onemailsubmit}
              className="footer-button mr-2"
            >
              {translation.email}
            </Link>
          </Box>
        </footer>
      </Container>
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
