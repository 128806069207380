import { ReactChild, ReactChildren, useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = ({ children }: { children: ReactChild | ReactChildren }) => {
  const  pathname  = useLocation(); 
  useEffect(() => { 
    window.history.scrollRestoration="manual";
    window.scrollTo(0, 0); 
  }, [pathname]); 
    

  return (<>{children}</>);
};

export default ScrollToTop;