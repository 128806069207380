import * as React from "react";
import { Backdrop, Box } from "@material-ui/core";
import { ApplicationState, article } from "../types/Master";
import { Container } from "reactstrap";
import AlertMessage from "./AlertMessage";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import UseTranslation from "../services/UseTranslation";
import { GetArticleDetailContent } from "../services/CustomerService";
import { Dispatch } from "redux";
import { action_types } from "../types/ActionTypes";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.isLoading,
    kbArticles: state.kbarticles,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
    return {
        setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
        setKbArticles: (val: article[]) =>
            dispatch({ type: "SET_KB_ARTICLES", payload: val }),
    };
};

const ArticleDetails = (props: any) => {
    const paramsid = props.match.params.id;
    const history = useHistory();
    const { isLoading, kbArticles, setLoader, setKbArticles } = props;
    const [articleDetail, setArticleDetail] = React.useState<article>();

    React.useEffect(() => {
        kbArticles.every((item: article) => {
            if (item.PortalUrl == paramsid) {
                if (!item.isContentUpdated) {
                    setLoader(true);
                    GetArticleDetailContent(item)
                        .then((res) => {
                            setLoader(false);
                            setArticleDetail(res);
                        })
                        .catch((ex) => {
                            console.error(ex);
                            setLoader(false);
                        });
                } else {
                    setArticleDetail(item);
                }
                return false;
            } else return true;
        });
    }, [paramsid, kbArticles]);

    React.useEffect(() => {
        if (articleDetail) {
            kbArticles.map((art: article) => {
                if (articleDetail.PortalUrl == art.PortalUrl) {
                    art.Content = articleDetail.Content;
                    art.isContentUpdated = true;
                }
            });
            setKbArticles(kbArticles);
        }
    }, [articleDetail]);

    return (
        <React.Fragment>
            <Backdrop style={{ zIndex: 2250 }} open={isLoading}>
                <Box className="loading-icon" />
            </Backdrop>
            <Container>
                <Box className="py-4">
                    <Container>
                        <AlertMessage />
                    </Container>
                </Box>
                <Box className="py-4">
                    <Container>
                        <Link
                            onClick={() => history.goBack()}
                            to="Javascript:void(0);"
                            style={{
                                fontWeight: "bold",
                                color: "#343a40 !important",
                                fontSize: "15px",
                            }}
                        >
                            {translation.back}
                        </Link>
                    </Container>
                </Box>
                {articleDetail && (
                    <Container>
                        <h3 className="light-grey-color-02 bi-bold-20">
                            {renderHtml(articleDetail.Title)}
                        </h3>
                        <div className="py-4">{renderHtml(articleDetail.Content)}</div>
                    </Container>
                )}
            </Container>
        </React.Fragment>
    );
};

function renderHtml(val: string) {
    return <div dangerouslySetInnerHTML={{ __html: val }} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetails);
