import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { GetAlertMessage } from "../services/CustomerService";
import { AlertMessage } from "../types/Master";
const Alertmessage = (props: any) => {
    const [data, setData] = React.useState<AlertMessage[]>([]);
    const [loadAlert, setLoadAlert] = React.useState(false);
    React.useEffect(() => {
        GetAlertMessage()
            .then((res) => {
                if (res) {
                    if (res != null) {
                        setLoadAlert( true);
                        setData(res); }
                }
            })
            .catch((ex) => {
                setLoadAlert(false);
                console.error(ex);
            });
    }, []);
    return (
        <React.Fragment>
            {loadAlert && (<Box className="row"> <Box className="col-12"><Box className="alert-message">
                        {data.map((item, index) => { 
                            return <MuiAlert
                                variant="standard"
                                severity="warning">
                                <Typography>{renderHtml(item.Message)} </Typography>

                            </MuiAlert>
                        })} 
            </Box>  </Box></Box>
            )}
            </React.Fragment>
    )
};
function renderHtml(val: string) {
    return <div dangerouslySetInnerHTML={{ __html: val }} />;
}
export default Alertmessage;
