export interface ApplicationState {
  isLoading: boolean;
  kbarticles: article[];
  formSuccess: boolean;
  callSuccess: boolean;
}
export interface article {
  PublicNumber: string;
  Id: any;
  Title: string;
  CreatedDate: any;
  MajorVersionNumber: number;
  MinorVersionNumber: number;
  Content: string;
  Showontop: boolean;
  Modifiedon: string;
  Subject: string;
  PortalUrl: string;
  isContentUpdated: boolean; //if img src is updated
}
export interface AlertMessage {
  Name: string;
  Message: string;
}
export interface ConfigInfo {
  EnableChatWidget: any;
  EnableCallback: any;
}

export const mimeDict = {
  txt: "text/plain",
  pdf: "application/pdf",
  docx: "application/msword",
  xlsx: "application/vnd.ms-excel",
  png: "image/png",
  jpeg: "image/jpeg",
  gif: "image/gif",
  rar: "application/zip",
  stream: "application/octet-stream",
};

export enum categories {
  "Andet" = <any>"Andet",
  "Administration" = <any>"Administration",
  "Annoncering" = <any>"Annoncering",
  "Finansiering" = <any>"Finansiering",
  "Hjemmesider" = <any>"Hjemmesider",
  "Kundesager" = <any>"Kundesager",
  "Priser og abonnementer" = <any>"Priser_og_abonnementer",
  "Abningstider" = <any>"Abningstider",
  "BILINFOVejledning" = <any>"BILINFO Vejledning",
  "all" = <any>"all",
}
export interface RedirectOptions {
  from: string;
  to: string;
  status: number;
}
