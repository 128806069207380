import * as React from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';

import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./Home";
import ArticleDetails from './ArticleDetails';
import CallmeBack from './CallmeBack';
import ArticlestopicList from './ArticlesList';
import ContactSupport from './ContactSupport';
import { RedirectOptions } from '../types/Master';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Header />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route path='/contactsupport' component={ContactSupport} />
            <Route path='/callmeback' component={CallmeBack} />
            <Route path='/articledetails/:id' component={ArticleDetails} />
            <Route path='/articlesList/:topic' component={ArticlestopicList} />
            <RedirectWithStatus status={301} from="/*" to="/" />          
        </Switch>
        <Footer />
    </React.Fragment>
);

function RedirectWithStatus(redirectOptions: RedirectOptions) {
    return (
        <Route
            render={({ staticContext }) => {
                // there is no `staticContext` on the client, so
                // we need to guard against that here
                if (staticContext) staticContext.statusCode = redirectOptions.status;
                return <Redirect from={redirectOptions.from} to={redirectOptions.to} />;
            }}
        />
    );
}
