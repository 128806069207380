import * as React from "react";
import { Container, Navbar, NavLink } from "reactstrap";
import { Box, Typography } from "@material-ui/core";
import "./index.css";
import UseTranslation from "../services/UseTranslation";
import Autocompletion from "../components/AutoComplete";
import { Link } from "react-router-dom";

const translation = UseTranslation();
const Header = (props: any) => {

    return (
        <React.Fragment>
            <Navbar className="header-section border-bottom">
                <Container>
                   
                    <header className="d-flex flex-wrap justify-content-between py-3 w-100">
                        <Box className="logo d-flex align-items-center">
                            <Link
                                to="/"
                                className="d-flex align-items-center text-dark text-decoration-none"
                            >
                                <img
                                    src={require("../images/logo.png")}
                                    alt="Bilinfo"
                                    title="Bilinfo"
                                    className="img-fluid"
                                />
                            </Link>
                        </Box>

                        <form className="col-12 col-sm-6 px-0">
                            <Typography className="mb-2">
                                {translation.SearchTitle}
                            </Typography>
                            <Box className="search-main d-flex">
                                <Autocompletion />
                            </Box>

                        </form>
                    </header>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}; 
export default Header;
