import * as React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "./store/reducers";
import ScrollToTop from "./components/ScrollToTop";
import Error from "./components/Error";

const store = createStore(reducers);

export default () => (
  <Provider store={store}>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/index.html" component={Error} />
          <Route path="/error" component={Error} />
          <Route path="/" component={Layout} />
        </Switch>
      </ScrollToTop>
    </Router>
  </Provider>
);
