import * as React from "react";
import { useState } from "react";
import { Box, List, ListItem, Grid } from "@material-ui/core";
import { ApplicationState, article, categories } from "../types/Master";
import { Container } from "reactstrap";
import AlertMessage from "./AlertMessage";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import UseTranslation from "../services/UseTranslation";

const mapStateToProps = (state: ApplicationState) => ({
  kbArticles: state.kbarticles,
});

const translation = UseTranslation();

const ArticlesList = (props: any) => {
  const { kbArticles } = props;
  const [noOfRecordsToShow, setNoOfRecordsToShow] = useState(20);
  const [data, setData] = React.useState<article[]>([]);
  const history = useHistory();
  const inputValue = props.match.params.topic;
  let categoryName: any = decodeURIComponent(props.match.params.topic);
  let paramsname: any = categories[categoryName];
  let daCategoryName =
    translation.getString(categoryName) == null
      ? paramsname
      : translation.getString(categoryName);

  React.useEffect(() => {
    getData();
  }, [kbArticles]);

  React.useEffect(() => {
    getData();
  }, [inputValue]);

  const loadMore = () => {
    setNoOfRecordsToShow(noOfRecordsToShow + 20);
  };

  const getData = () => {
    if (kbArticles && kbArticles.length > 0) {
      {
        if (daCategoryName) {
          if (daCategoryName == "all") {
            setData([...kbArticles]);
          } else {
            let data = kbArticles.filter((item: article) => {
              return (
                item.Subject.replace(/\s/gi, "") ===
                daCategoryName.replace(/\s/gi, "")
              );
            });
            setData([...data]);
          }
        } else {
          let filtered: article[] = [];
          setData([]);
          let listOfWords = inputValue.split(" ");
          kbArticles.forEach((item: article) => {
            let isAllWordsPresent = false;
            listOfWords.every((word: string) => {
              if (
                item.Content.toLowerCase().includes(word.toLowerCase()) ||
                item.Title.toLowerCase().includes(word.toLowerCase())
              ) {
                isAllWordsPresent = true;
                return true;
              } else {
                isAllWordsPresent = false;
                return false;
              }
            });
            if (isAllWordsPresent) filtered.push(item);
          });
          filtered.length > 0 && setData(filtered);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Box className="py-4">
          <Container>
            <AlertMessage />
          </Container>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {daCategoryName && daCategoryName != "all" && (
            <h3 className="light-grey-color-02 bi-bold-20 bi-uppercase">
              {daCategoryName}
            </h3>
          )}
        </Box>
        <Box className="row py-2">
          <Container>
            <Link
              onClick={() => history.goBack()}
              to="Javascript:void(0);"
              style={{
                fontWeight: "bold",
                color: "#343a40 !important",
                fontSize: "15px",
              }}
            >
              {translation.back}
            </Link>
          </Container>
          <Box className="col-12 my-4">
            <Grid className="article-bg-list article-all">
              {
                <List>
                  {data.map((item, index) => {
                    if (index < noOfRecordsToShow)
                      return (
                        <ListItem>
                          <Link to={"/articledetails/" + item.PortalUrl}>
                            {renderHtml(item.Title)}
                          </Link>
                        </ListItem>
                      );
                  })}
                </List>
              }
              <List>
                {data.length > noOfRecordsToShow && (
                  <button onClick={loadMore}> {translation.loadmore} </button>
                )}
              </List>
            </Grid>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

const renderHtml = (val: string) => {
  return <div dangerouslySetInnerHTML={{ __html: val }} />;
};

export default connect(mapStateToProps)(ArticlesList);
