import { ApplicationState } from "../../types/Master";
import {
  action_types,
  SET_LOADER,
  SET_KB_ARTICLES,
  SET_SUCCESS,
  SET_CALL_SUCCESS,
} from "../../types/ActionTypes";

const initialState: ApplicationState = {
  isLoading: false,
  kbarticles: [],
  formSuccess: false,
  callSuccess: false,
};

export default (
  state: ApplicationState = initialState,
  action: action_types
): ApplicationState => {
  switch (action.type) {
    case SET_LOADER:
      return { ...state, isLoading: action.payload };
    case SET_KB_ARTICLES:
      return { ...state, kbarticles: action.payload };
    case SET_SUCCESS:
      return { ...state, formSuccess: action.payload };
    case SET_CALL_SUCCESS:
      return { ...state, callSuccess: action.payload };
    default:
      return state;
  }
};
