import {
    Box,
    Button,
    Card,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Checkbox,
    FormControl,
    Backdrop,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { CreateEmailCase } from "../services/CustomerService";
import UseTranslation from "../services/UseTranslation";
import { getfileType } from "../services/Shared";
import Defines from "../config";
import { connect } from "react-redux";
import { ApplicationState } from "../types/Master";
import { Dispatch } from "redux";
import { action_types, SET_SUCCESS } from "../types/ActionTypes";
const translation = UseTranslation();
declare var grecaptcha: any;
const mapStateToProps = (state: ApplicationState) => ({
    formSuccess: state.formSuccess,
});
const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
    return {
        SetSuccess: (val: boolean) =>
            dispatch({ type: "SET_SUCCESS", payload: val }),
    };
};
const EmailForm = (props: any) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [Navn, setNavn] = React.useState<string>("");
    const [Email, setEmail] = React.useState<string>("");
    const [Telefonnummer, setTelefonnummer] = React.useState<string>("");
    const [Kategori, setKategori] = React.useState<string>("");
    const [Emne, setEmne] = React.useState<string>("");
    const [Beskrivelse, setBeskrivelse] = React.useState<string>("");
    const [isAcceptReturnPolicy, setIsAcceptReturnPolicy] =
        useState<boolean>(false);
    const [EmailValidationMessage, setEmailValidationMessage] =
        React.useState<string>("");
    const [files, setFiles] = useState<FileList | null>();
    const [isFileSizeExceeded, setFileSizeExceeded] = useState<boolean>(false);
    const [ErrorMessage, setErrorMessage] = React.useState<string>("");
    const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target.files;
        if (files && files.length > 0 && files[0].size > 25 * 1024 * 1024) {
            setFileSizeExceeded(true);
        } else {
            setFiles(event.target.files);
            setFileSizeExceeded(false);
        }
    };
    const history = useHistory();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validate()) {
            const data = new FormData(event.currentTarget);
            setIsLoading(true);

            if (files && files.length > 0) {
                let file = files[0];
                let fileName = file.name;
                let extension = fileName.split(".").pop();
                let fileType = extension
                    ? getfileType(extension)
                    : "application/octet-stream";
                data.append("fileName", fileName);
                data.append("file", file);
                data.append("contentType", fileType);
            }

            grecaptcha.ready(function () {
                grecaptcha
                    .execute(Defines.reCAPTCHA_SITE_KEY, { action: "submit" })
                    .then(function (token: any) {
                        data.append("token", token);
                        CreateEmailCase(data)
                            .then((res) => {
                                setIsLoading(false);
                                if (res.status == "Queue_Error") {
                                    props.SetSuccess(false);
                                    setToDefaults();
                                    alert(
                                        "Submit action Failed. Please refresh page and try again."
                                    );
                                } else if (res.status == "Recaptcha_Error") {
                                    props.SetSuccess(false);
                                    setErrorMessage(translation.recaptchafailuremsg);
                                } else if (res.status != "Error") {
                                    props.SetSuccess(true);
                                    setToDefaults();
                                } else {
                                    props.SetSuccess(false);
                                    setErrorMessage(translation.techinicalerrormsg);
                                }
                            })
                            .catch((ex) => {
                                setIsLoading(false);
                                props.SetSuccess(false);
                                setErrorMessage(translation.techinicalerrormsg);
                                console.log(ex);
                            });
                    });
            });
        }
    };

    const validate = () => {
        let isValid = true;
        setErrorMessage("");
        setEmailValidationMessage("");
        if (Email != "") {
            const isEmailValid = /@/.test(Email);
            if (!isEmailValid) {
                setEmailValidationMessage(translation.InvalidEmailAddress);
                isValid = false;
            }
        }
        return isValid;
    };

    const setToDefaults = () => {
        setNavn("");
        setEmail("");
        setTelefonnummer("");
        setKategori("");
        setEmne("");
        setBeskrivelse("");
        setFiles(null);
        setIsAcceptReturnPolicy(false);
        setFileSizeExceeded(false);
        setEmailValidationMessage("");
    };
    const onLukClick = () => {
        history.push("/");
    };

    return (
        <React.Fragment>
            <Box>
                <Backdrop
                    style={{
                        zIndex: 2250,
                    }}
                    open={isLoading}
                >
                    <Box className="loading-icon" />
                </Backdrop>
                {!props.formSuccess && (
                    <Box
                        component="form"
                        className="form-section"
                        onSubmit={handleSubmit}
                    >
                        <React.Fragment>
                            <h3 className="mb-3 light-grey-color-02">
                                {translation.emailCustomerService}
                            </h3>
                        </React.Fragment>

                        <Card className="">
                            <TextField
                                required
                                id="Navn-textbox"
                                label="Navn"
                                name="Navn"
                                variant="outlined"
                                fullWidth
                                value={Navn}
                                onChange={(e) => setNavn(e.target.value as string)}
                                onInvalid={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity(
                                        "Udfyld venligst dette felt"
                                    )
                                }
                                onInput={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity("")
                                }
                            />
                            <div className="bi-errorMessageBlock">
                                <TextField
                                    required
                                    id="Email-textbox"
                                    label="Email"
                                    name="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value as string)}
                                    onInvalid={(e) =>
                                        (e.target as HTMLInputElement).setCustomValidity(
                                            "Udfyld venligst dette felt"
                                        )
                                    }
                                    onInput={(e) =>
                                        (e.target as HTMLInputElement).setCustomValidity("")
                                    }
                                />
                                <span className="text-danger">{EmailValidationMessage} </span>
                            </div>
                            <TextField
                                required
                                id="Telefonnummer-textbox"
                                label="Telefonnummer"
                                name="Telefonnummer"
                                variant="outlined"
                                fullWidth
                                value={Telefonnummer}
                                onKeyPress={(e) =>
                                    !/^[ +()0-9]+$/.test(e.key) && e.preventDefault()
                                }
                                onChange={(e) => setTelefonnummer(e.target.value as string)}
                                onInvalid={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity(
                                        "Udfyld venligst dette felt"
                                    )
                                }
                                onInput={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity("")
                                }
                            />
                            <FormControl fullWidth className="contact-select">
                                <InputLabel htmlFor="select-case-type">Kategori *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="contact-select-type"
                                    name="Kategori"
                                    label={"Kategori *"}
                                    fullWidth
                                    className="dropdown"
                                    style={{ minWidth: 120 }}
                                    required
                                    value={Kategori}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setKategori(e.target.value as string);
                                    }}
                                    onInvalid={(e) => {
                                        if (Kategori !== "") {
                                            (e.target as HTMLInputElement).setCustomValidity("");
                                            e.preventDefault();
                                        } else {
                                            (e.target as HTMLInputElement).setCustomValidity(
                                                "Udfyld venligst dette felt"
                                            );
                                        }
                                    }}
                                >
                                    <MenuItem value={""}>- Ingen -</MenuItem>
                                    <MenuItem value={"Andet"}>Andet</MenuItem>
                                    <MenuItem value={"Administration"}>Administration</MenuItem>
                                    <MenuItem value={"Annoncering"}>Annoncering</MenuItem>
                                    <MenuItem value={"Finansiering"}>Finansiering</MenuItem>
                                    <MenuItem value={"Hjemmesider"}>Hjemmesider</MenuItem>
                                    <MenuItem value={"Kundesager"}>Kundesager</MenuItem>
                                    <MenuItem value={"Priser og abonnementer"}>
                                        Priser og abonnementer
                                    </MenuItem>
                                    <MenuItem value={"Fakturasporgsmal"}>{translation.invoiceQuestions}</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                required
                                id="Emne-textbox"
                                label="Emne"
                                name="Emne"
                                variant="outlined"
                                fullWidth
                                value={Emne}
                                onChange={(e) => setEmne(e.target.value as string)}
                                onInvalid={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity(
                                        "Udfyld venligst dette felt"
                                    )
                                }
                                onInput={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity("")
                                }
                            />
                            <TextField
                                required
                                id="Beskrivelse-textbox"
                                label="Beskrivelse"
                                name="Beskrivelse"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                maxRows={4}
                                value={Beskrivelse}
                                onChange={(e) => setBeskrivelse(e.target.value as string)}
                                onInvalid={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity(
                                        "Udfyld venligst dette felt"
                                    )
                                }
                                onInput={(e) =>
                                    (e.target as HTMLInputElement).setCustomValidity("")
                                }
                            />
                        </Card>

                        <Box className="d-flex mb-3">
                            <Button
                                variant="contained"
                                component="label"
                                className="upload-file"
                            >
                                {"Attachments"}
                                <input
                                    type="file"
                                    accept="image/*,.csv,.pdf,.txt,.doc,.docx,.xml"
                                    onChange={(e) => handleFilesAdded(e)}
                                    hidden
                                />
                            </Button>
                            {files && (
                                <Typography className="font-16-bold line-height-50">
                                    {files[0].name}
                                </Typography>
                            )}
                        </Box>
                        {isFileSizeExceeded && (
                            <Box className="d-flex mb-3">
                                <Typography className="font-16-bold line-height-50">
                                    {translation.FileSizeExceed}
                                </Typography>
                            </Box>
                        )}

                        <Box className="form-checkbox">
                            <Checkbox
                                name="AcceptReturnPolicy"
                                value={isAcceptReturnPolicy}
                                onClick={() => setIsAcceptReturnPolicy(!isAcceptReturnPolicy)}
                                color="primary"
                                checked={isAcceptReturnPolicy}
                                className="email-checkbox"
                            />
                            {translation.emailFormReturnTerms}
                            <a href={Defines.POLICY_URL} className="ml-1" target="_blank">
                                her
                            </a>
                        </Box>
                        {ErrorMessage != "" && (
                            <div className="captcha-error mb-2">
                                <span>{ErrorMessage}</span>
                            </div>
                        )}
                        <Box className="justify-content-end d-flex">
                            <Button
                                type="submit"
                                variant="contained"
                                className="ms-auto btn-button btn-blue"
                                disabled={!isAcceptReturnPolicy}
                            >
                                {"Send"}
                            </Button>
                        </Box>
                    </Box>
                )}
                {props.formSuccess && (
                    <Box className="success-wrapper mt-4">
                        <h4>
                            <span className="checkSuccess"></span>
                            {translation.Thankyou}
                        </h4>
                        <Typography>{translation.ReturnASAP}</Typography>
                        <Button value="Luk" className="btn-success" onClick={onLukClick}>
                            {translation.close}
                        </Button>
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);
