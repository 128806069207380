import * as React from "react";
import { 
    Grid,
    List, 
    ListItemText,
    ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { categories } from "../types/Master";
import UseTranslation from "../services/UseTranslation";
const translation = UseTranslation();

class Home extends React.Component {
    render() {
        return (
            <Grid item xs={12} className="featured_product">
                <List className="row" component={"div"}>
                    <Link
                        className="text-center col-6 col-md-4 mb-2"
                      
                        to={"/articlesList/" + categories["Administration"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/administration-img.jpg")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Administration" />
                    </Link>
                    <Link
                        className="text-center col-6 col-md-4 mb-2"
                       
                        to={"/articlesList/" + categories["Annoncering"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/annorcing-img.jpg")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Annoncering" />
                    </Link>
                    <Link
                        className="text-center col-6 col-md-4 mb-2"
                        
                        to={"/articlesList/" + categories["Finansiering"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/finanicial-img.jpg")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Finansiering" />
                    </Link>
                    <Link
                        className="text-center col-6 col-md-4"
                        
                        to={"/articlesList/" + categories["Hjemmesider"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/huemmesider-img.jpg")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Hjemmesider" />
                    </Link>
                    <Link
                        className="text-center col-6 col-md-4"
                        
                        to={"/articlesList/" + categories["Kundesager"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/kundesager-img.jpg")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Kundesager" />
                    </Link>
                    <Link
                        className="text-center col-6 col-md-4"
                        
                        to={"/articlesList/" + categories["Priser og abonnementer"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/priser-img.jpg")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Priser OG Abonnementer" />
                    </Link>

                    <Link
                        className="text-center col-6 col-md-4"

                        to={"/articlesList/" + categories["Abningstider"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/abningstider-img.png")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.Abningstider} />
                    </Link>

                    <Link
                        className="text-center col-6 col-md-4"

                        to={"/articlesList/BILINFOVejledning"}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={require("../images/vejledning-img.png")}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.Bilinfovejledning} />
                    </Link>
                </List>
            </Grid>
        );
    }
}

export default Home;
