export default {
  SearchTitle: "Hvordan kan vi hjælpe?",
  SearchPlaceholder: "Stil et spørgsmål eller søg på et emne",
  FaqTitle: "Ofte stillede spørgsmål",
  attachment: "Attachment",
  backToCases: "Back to Cases",
  businessPhone: "Business Phone",
  cancel: "Cancel",
  cases: "Cases",
  callFormHeading:
    "Ring mig op (Bemærk at vi ringer til dig fra skjult nummer)",
  callFormSubHeading: "Vi bestræber os på at ringe tilbage inden 5 minutter",
  email: "Email",
  emailFormReturnTerms:
    "Jeg er indforstået med Bilinfo persondatapolitikker, som er beskrevet ",
  loadmore: "Indlæs mere",
  questions: "spørgsmål",
  noResults: "Ingen resultater",
  InvalidEmailAddress: "Ugyldig emailadresse",
  Thankyou: "Tak for din besked.",
  ReturnASAP: "Vi vender tilbage snarest muligt.",
  OfflineASAP:
    "Vi har lige nu lukket for telefonsupport - Kontakt os i stedet via e-mail, og vi vender tilbage hurtigst muligt",
  emailCustomerService: "Send en email til Kundeservice",
  contactCustomerService: "Kontakt Bilinfo Support",
  callme: "Ring mig op",
  footerlabel:
    "Telefonens åbningstider: Alle hverdage 9-11 og 13.30-14.30 </br> på tlf: 33330303 </br> Ved spørgsmål vedr faktura/betaling kan vores kundebogholderi kontaktes alle hverdage kl. 9-15 på  tlf: 87313187",
  viewAll: "Vis alle",
  FileSizeExceed: "Filstørrelsen må ikke overstige 20 MB.",
  close: "Luk",
  errorMessage:
    "Undskyld! vi kan ikke fortsætte med denne handling, da godkendelsen ikke er gyldig.",
  category: "Emne",
  required: "Udfyld venligst dette felt",
  back: "< Tilbage",
  recaptchafailuremsg:
    "Der opstod desværre en sikkerhedsmæssig fejl ved din supportadmodning og du bedes prøve igen (og hvis det ikke virker, prøv da gerne en anden browser eller computer/telefon)",
  techinicalerrormsg:
        "Din supportanmodning er desværre fejlet af tekniske årsager. Du bedes prøve igen.",
  invoiceQuestions: "Fakturaspørgsmål",
  Abningstider: "Åbningstider",
  Bilinfovejledning: "BILINFO Vejledning"
};
