export const SET_LOADER = "SET_LOADER";
export const SET_KB_ARTICLES = "SET_KB_ARTICLES";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_CALL_SUCCESS = "SET_CALL_SUCCESS";

interface SetLoader {
    type: typeof SET_LOADER;
    payload: any;
}

interface SetKbArticles {
    type: typeof SET_KB_ARTICLES;
    payload: any;
}
export type action_types =
    SetLoader | SetKbArticles | SetSuccess | SetCallSuccess;

interface SetSuccess {
    type: typeof SET_SUCCESS;
    payload: any;

}
interface SetCallSuccess {
    type: typeof SET_CALL_SUCCESS;
    payload: any;
}
