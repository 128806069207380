import * as React from 'react';
import { Box } from "@material-ui/core";
import Articles from './Articles';
import CallForm from './CallForm';
import { Container } from "reactstrap";
import AlertMessage from "./AlertMessage";


class CallmeBack extends React.Component {
    render() {
        return (
            <Box className="contact-support-main">
                <Container>
                    <Box className="py-4">
                        <Container>
                            <AlertMessage />
                        </Container>
                    </Box>
                    <Box className="row pb-5">
                        <Box className="col-12 col-sm-6">
                            <CallForm />
                        </Box>

                        <Box className="col-12 col-sm-6">
                            <Articles />
                        </Box>
                    </Box>
                </Container>

            </Box>

        );
    }
}

export default CallmeBack;


